@font-face {
  font-family: 'Prompt';
  src: url('../fonts/Prompt-Regular.ttf'); /* IE9 Compat Modes */
  /*src: url('../assets/fonts/Prompt-Regular.ttf'); !* IE9 Compat Modes *!*/
}

@font-face {
  font-family: 'Prompt-Medium';
  src: url('../fonts/Prompt-Medium.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Prompt-Thin';
  src: url('../fonts/Prompt-Thin.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Prompt-Bold';
  src: url('../fonts/Prompt-Bold.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../fonts/Roboto-Regular.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Medium.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('../fonts/Roboto-Bold.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Sarabun-Regular';
  src: url('../fonts/Sarabun-Regular.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Sarabun';
  src: url('../fonts/Sarabun-Medium.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Sarabun-SemiBold';
  src: url('../fonts/Sarabun-SemiBold.ttf'); /* IE9 Compat Modes */
}

html {
  margin-left: calc(100vw - 100%);
}

body {
  margin: 0;
  font-family: 'Roboto', 'Sarabun', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f4f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.space-between {
  justify-content: space-between;
}

.flex-grow {
  flex-grow: 1;
}

.text-center {
  text-align: center;
}

.text-underline {
  text-decoration: underline;
}

.text-link {
  text-decoration: underline;
  color: #29aa68;
}
